import MDinput from '@/components/MDinput';
import { validatorRequire } from '@/utils/validators';
import Select from '../../../components/DSE/Select';

export function getFormDefinition(vue) {
  return {
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    snelStartGroupID: {
      type: MDinput,
      props: {
        type: 'number',
        precision: 0,
        max: maxNumber
      }
    },
    defaultProductCategoryID: {
      type: Select,
      props: {
        caption: 'common.defaultProductCategory',
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['productCategory/isLoading'],
        options: vue.$store.getters['productCategory/loadedItems']
      }
    }
  };
}

const maxNumber = 999999999999999;

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item
  };
  return form;
}
